.instructions-card{
    margin-top: 1rem!important;
}
.instructions-title{
    text-align: left;
    font-size: 1.3em;
}
.instructions-date{
    text-align: right;;
}
.instructions-card-description{
    margin-top: 10px;
}