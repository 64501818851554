.checkout-payment-buttons {
  margin-left: 5% !important;
  width: 90% !important;
}
.checkout-payment-buttons-button.button-confirm {
  width: 68% !important;
}
.checkout-payment-buttons-button.button-back {
  width: 30% !important;
}
.label-payment-option-inline {
  background: transparent !important;
}
.label-payment-option {
  width: 100% !important;
}
.payment-option-email input:focus {
  box-shadow: none !important;
  border-color: #2185d0 !important;
}
.payment-option-email {
  border-bottom: 1px solid rgba(34, 36, 38, .15) !important;
}
