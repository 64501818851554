.no-outline {
  outline: none;
}

.image-gallery-right-nav::before,
.image-gallery-left-nav::before,
.image-gallery-fullscreen-button::before {
  text-shadow: 0 1px 1px #999;
}

.image-gallery-right-nav:hover::before,
.image-gallery-left-nav:hover::before {
  color: blue;
}
.product-card-button-goto-cart {
  padding-right: 0.5em!important;
}