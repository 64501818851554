.shop-name {
  text-align: left;
  flex: 1;
  width: 110px;
  font-size: 11px!important;
}
.shop-text {
  font-size: 10.5px!important;
}
.shop-name a:focus,
.shop-name a {
  color: white;
  font-size: 11px!important;
}
.shop-icon {
  color: white;
}
.shop-text-item {
  font-size: small;
}
.cart-counter.ui.circular.label,
.task-counter.ui.circular.label{
  left: 80% !important;
  border: 1px solid !important;
  border-color:  white !important;
}
.shop-icon-item:focus,
.shop-icon-item,
.ui.secondary.inverted.menu a.item{
  color: white!important;
  font-size: small;
}
.ui.button.circular {
  color: white;
  background: transparent;
  margin: -0.5em 0 !important;
  font-size: 1.37rem !important;
}
.nav-top-bar .menu-search-item {
  width: calc(100% - 110px);
  position: relative;
}
.nav-top-bar .menu-search-item form {
  width: 100%!important;
  display: flex;
}
.nav-top-bar .input.search {
  width: calc(100% - 121px)!important;
}
.search-dropdown {
  min-width: 86px!important;
  background: white!important;
}
.search-dropdown .text{
    padding: 1em 0.25em;
}
.search-dropdown .dropdown.icon {
  float: right!important;
  padding: 16px 5px 0 0!important;
  margin: auto 0!important;
}
.input.search .search-button {
  border-bottom-left-radius: 0!important;
  border-top-left-radius: 0!important;
}
.input.search .search-field {
  border-bottom-right-radius: 0!important;
  border-top-right-radius: 0!important;
}
.input.search .search-field:active,
.input.search .search-field:focus{
  border-top: none!important;
  border-bottom: none!important;
  border-left: none!important;
}
.nav-top-bar > *,
.nav-bottom-bar > * {
  margin: 0 auto!important;
}

@media (max-width: 370px) {
  .shop-icon-item:focus,
  .shop-icon-item,
  .ui.secondary.inverted.menu a.item{
    font-size: x-small;
  }
  .shop-text-item {
    font-size: small;
  }
}