.service-payment-container {
    margin: 0 5px;
}

.title-text {
    color: #2185D0;
    font-size: 17px;
    font-weight: bold;
    margin: 0 auto;
}

.service-payment-header {
    display: flex;
    align-items: center;
}

.service-payment-header-img {
    cursor: pointer;
}

.service-payment-month-container {
    margin-top: 20px;
}

.payment-date {
    color: #2185D0;
    font-size: 15px;
    font-weight: 600;
    padding-left: 7px;
}

.payment-data {
    display: flex !important;
    margin: 10px 0 0 0 !important;
    padding: 10px 5px !important;
    background: white !important;
    /*height: 100px;*/
    border-radius: 10px !important;
    box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.1);
    width: 100% !important;
    flex-direction: column;
}

.data-header {
    display: flex;
    margin: 0 55px 0 15px;
    justify-content: space-between;
    font-size: 13px;
}

.services-desc-box {
    padding: 3px 10px 3px 15px;
    border-radius: 5px !important;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.services-amount {
    display: flex;
    gap: 5px;
    align-items: center;

}

.container-services {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    background-color: #F6FCFF;
    padding: 5px 20px 8px 8px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: 5px;
    max-height: 145px;
    /*overflow: auto;*/
}

.service-info {
    display: flex;
    flex-direction: row;
    gap: 15px;
    line-height: normal;
}

.service-id {
    font-size: 12.5px;
    font-weight: 600;
}

.service-desc {
    font-size: 12.5px;
}

.service-price {
    display: flex;
    float: left;
    margin: 0 0 0 auto ;
    font-size: 12.5px;
    font-weight: 600;
}


.box-arrow {
    transition: transform 0.3s ease;

}

.box-arrow.open {
    transform: rotate(180deg);
}
