.order-update-loader {
  position: fixed!important;
  top: 50%!important;
  left: 50%!important;
}
.order-mini-card-header-first-col {
  width: 30%!important;
}
.order-mini-card-header-first-col img {
  max-width: 125px!important;
  width: 100%!important;
}
.order-mini-card-header-second-col {
  width: 70%!important;
}
.order-mini-card-mail,
.order-mini-card-edit-photo {
  float: right;
}
.button-create-again {
  width: 90%!important;
  margin: 1em 5%!important;
}
.order-mini-card-cancel-button {
  background: transparent!important;
  position: absolute!important;
  right: 5px!important;
  top: 10px!important;
  color: lightgrey!important;
  font-size: 20px!important;
}
.modal-order-badge {
  top: 1em!important;
  position: static;
}
.order-card-header {
  min-height: 1.5em;
}
.order-card-header div{
  margin-top: 0.5em;
}
.order-badge {
  margin: 1em 0 0 0;
  max-width: 480px!important;
  max-height: 300px!important;
  min-width: 480px!important;
  min-height: 300px!important;
}
.order-badge .content{
  margin: 0!important;
  padding: 0!important;
}

.react-html5-camera-photo video,
.image-preview.image-preview-fullscreen {
  max-width: 480px;
  margin: 0 auto;
}
.image-preview.image-preview-fullscreen {
  margin-bottom: 5px;
}
.order-card-header .label {
  margin: 0!important;
}
.order-card .cart-product .third-column {
  display: none!important;
}

@media (min-width: 802px) {
  .make-photo,
  .modal-order-badge,
  .modal-order-edit-address,
  .modal-expo-location {
    max-width: 800px !important;
    margin-left: calc((100% - 800px) / 2) !important;
  }
}

@media (max-width: 768px) {
  .order-card-header {
    margin: 2.5em 0 0!important;
  }
  .order-mini-card-contacts {
    display: grid;
  }
  .order-mini-card-mail {
    margin-top: 1em;
  }
}
