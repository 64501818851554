
.category-card-content {
  display: inline-flex;
}
.category-content-container {
  margin-bottom: 2em!important;
}
.category-card-image-href {
  width: 30%;
}
.category-meta-container {
  width: 70%;
  margin-top: 0!important;
  padding-top: 0!important;
}
.category-button-products {
  position: absolute;
  right: 0;
  bottom: 0;
}
.page-loader {
  position: fixed!important;
}
.scroll-loader-wrapper {
  width: 100% !important;
  text-align: center;
}