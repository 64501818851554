.tasks-header {
    position: relative;
}
.filter-button {
    position: absolute;
    top: 0;
    right: 5%;
}
.tasks-view .divider {
    margin: 1em!important;
}
.page-loader {
    position: fixed!important;
}
.scroll-loader-wrapper {
    width: 100% !important;
    text-align: center;
}