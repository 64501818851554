@import url('./fonts.css');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

button, input, optgroup, select, textarea, h4, h2, span, div {
  font-family: "Montserrat", sans-serif !important;
}
