.modal-task-filter {
    position: static!important;
    width: 95%!important;
    height: auto;
    max-width: 410px!important;
    max-height: 650px!important;
}

.modal-orderby-dropdown .text {
    width: 95px!important;
}

.modal-direction-dropdown .text {
    width: 45px!important;
}

.modal-sort-divider{
    margin: 0 1rem!important;
}

.grid-sort .dropdown.icon {
    margin: 0!important;
}

.modal-filter-row {
    padding-bottom: 0!important;
    padding-top: 0!important;
    margin-top: 1em;
}

.modal-filter-row:last-of-type {
    margin-bottom: 1em;
}


