.edit-profile-container {
    margin: 10px;
}

.edit-profile-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit-profile-header-text {
    color: #2185D0;
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.edit-profile-body {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.edit-profile-body-img {
    width: 131px!important;
    height: 131px!important;
    border-radius: 50% !important;
}

@media (max-width: 430px) {
    .edit-profile-body-img {
        width: 120px!important;
        height: 120px!important;
    }
}

.container-inp-one {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.container-inp-two {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.edit-icon-container {
    position: relative;
}

.edit-overlay {
    position: absolute;
    bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    opacity: 0;
    transition: opacity 0.15s ease;
    cursor: pointer;
}

.overlay-text {
    display: flex;
    margin: 0 auto;
    z-index: 2;
    font-size: 10px;
    gap: 3px;
    align-items: center;
}

.edit-icon-container:hover .edit-overlay {
    opacity: 1;
}

.container-inputs-edit {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: 90%;
}

.bio-edit-container {
    width: 100%;
}

.bio-name-edit {
    font-size: 14px;
    color: #2185D0;
    font-weight: 550;

}

.edit-input {
    margin-top: 5px!important;
    width: 100%!important;
    height: 35px!important;
    border-radius: 8px!important;
    outline: none!important;
    color: black!important;
    border: 1px transparent!important;
    box-shadow: 0 0 15px -10px black!important;
    font-size: 12px!important;
    padding-left: 5px!important;
}

.edit-profile-interests {
    margin-top: 16px!important;
}

.edit-profile-speciality {
    margin-top: 16px!important;
}

.interest-dropdown {
    margin-top: 5px!important;
    border-radius: 12px!important;
    box-shadow: 0 0 15px -10px black!important;
    width: 100% !important;
    font-size: 13px!important;
    cursor: pointer!important;
}

.ui.label {
    background-color: #2185D0!important;
    color: #fff!important;
    font-weight: 600!important;
    font-family: 'Montserrat', sans-serif;
}

.text-interests {
    font-size: 15px;
    color: #2185D0;
    font-weight: 600;
    margin-left: 5px!important;
}

.text-speciality {
    font-size: 15.5px;
    color: #2185D0;
    font-weight: 600;
    margin-left: 5px!important;
}


.speciality-form {
    width: 100%;
    margin-top: 5px!important;

}

.speciality-text-area {
    font-size: 13px!important;
    line-height: 13px !important;
    width: 100% !important;
    min-height: 90px!important;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px !important;
    border: none !important;
    border-radius: 10px;
    resize: none !important;
}

.edit-error {
    color: #D81524;
    font-size: 10px;
    line-height: 16px;
    font-weight: 700;
    hyphens: auto;
}

.character-count {
    position: absolute;
    right: 5px;
    top: 69px;
    font-size: 10px;
    color: gray;
}

.photogallery-container {
    margin-top: 16px;
}

.upload-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #2185D0;
    width: 90px;
    height: 25px;
    font-size: 13px;
    font-weight: 600;
    color: white;
    border-radius: 7px;
    margin-bottom: 10px;
}

.dragg-button {
    cursor: pointer!important;
    border-radius: 7px!important;
    height: 25px !important;
    display: flex!important;
    align-items: center!important;
    color: white!important;
    background-color: #2185D0!important;
    font-size: 13px!important;
}

.upload-images-box {
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px !important;
    width: 100%;
    padding-left: 7px;
    padding-top: 8px;
    padding-bottom: 9px;
    margin-top: 5px;
}

.upload-images-btns {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.delete-img-btn {
    width: 11px !important;
    height: 11px!important;
    background: white !important;
    border-radius: 12px!important;
}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-item {
    position: relative;
    width: 184px;
    height: 131px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    cursor: move;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.div-trash {
    position: absolute;
    top: 4px;
    right: 4px;
    background: white;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
}

.div-draggable {
    position: absolute;
    top: 4px;
    right: 28px;
    background: white;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
}

.icon-trash {
    padding-left: 2.5px!important;
}

.save-btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.save-btn {
    border-radius: 10px!important;
    width: 154px!important;
    height: 32px!important;
    font-size: 16px!important;
    color: white!important;
    font-weight: bold;
    background-color: #2185D0!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
}




/*-------------- PHONE MASK -------------*/

.bio-input-phone {
    display: flex!important;
    align-items: center!important;
    font-size: 13px !important;
    line-height: 16px;
    border-radius: 8px !important;
    padding: 0 !important;
    border: none !important;
    background: white!important;
    color: black!important;
    width: 100%!important;
    margin-top: 5px!important;
    box-shadow: 0 0 15px -10px black!important;
}
.bio-input-phone input.search {
    border-radius: 15px !important;
    /*background: lightgrey!important;*/
}
.bio-input-phone:focus {
    background: #EBE7E5!important;
}
.bio-input-phone input[type="text"]:focus {
    border: none !important;
    background: white!important;
}
.bio-input-phone input[type="text"].search:focus {
    border: none !important;
    background: white!important;
    caret-color: transparent!important;
}
#input-lastname {
    margin: 0 !important;
}
.bio-input-phone input#masked_phone,
.bio-input-phone .ui.selection.dropdown {
    border: none !important;
    /*padding-bottom: 4px;*/
    /*background: white!important;*/
    /*!*color: gray!important;*!*/
    /*font-size: 14px!important;*/
    border-radius: 15px !important;
}
.bio-input-phone {
    /*border: 1px solid #2185D0 !important;*/
}
.bio-input-phone .ui.selection.dropdown {
    height: 13px !important;
    padding-left: 0;
    padding-right: 0;
    background: white!important;
}
.bio-input-phone input.search {
    padding: 0 !important;
    text-align: center;
}
.bio-input-phone div.divider.text,
.bio-input-phone input.search {
    width: 30px !important;
    height: 21px!important;
}
.bio-input-phone #dropdown_country {
    width: 45px !important;
    /*background: transparent !important;*/
    padding: 0 !important;
    display: flex;
    align-items: center;
}
.bio-input-phone #dropdown_country:active,
.bio-input-phone #dropdown_country:hover,
.bio-input-phone #dropdown_country.active {
    box-shadow: none !important;
}
.bio-input-phone #dropdown_country input {
    background: transparent!important;
    top: 9px;
}
.bio-input-phone .flag {
    padding-left: 10px!important;
    margin-bottom: 3px!important;
}
.bio-input-phone .icon {
    color: transparent!important;
}
/*--------------------------------*/