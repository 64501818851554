
.calendar-table-cell-day-week{
    background: #EBF6FF !important;
    font-weight: normal !important;
    padding: 10px 10px !important;
}
.calendar-table {
    border-radius: 25px !important;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1) !important;
    position: relative !important;
    width: 95% !important;
    table-layout: fixed;
    margin-left: 2.5% !important;
}

.calendar-table-cell-day-week,
.calendar-table-cell-day,
.calendar-table-cell {
    width: auto;
    min-width: 50px;
    box-sizing: border-box;
}
.calendar-month-label{
    background: #EBF6FF;
    font-size:12px;
    font-weight: normal;
    padding: 6px 40px;
    border-radius: 5px;
    position: relative;
    top:13px;
    left:40px;
}
.calendar-table-cell-day{
    background: #F6F6F6 !important;
    padding: 1px 10px !important;
}
.calendar-table-cell {
    position: relative;
    height: 60px;
    width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.1);
    vertical-align: top;
    padding: 0;
}

.day-number {
    width: 100%;
    background-color: #F6F6F6;
    padding: 0 5px;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.task-id {
    margin-bottom: -10px;
    text-align: left;
    padding: 5px 0;
}
.calendar-table-cell{
    vertical-align: top;
    padding: 5px !important;
}
#calendar-table-downleft-cell{
    border-left: none !important;
}
.calendar-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom:-30px;
    margin-right: 30px;
}
.calendar-month-display {
    font-size: .9em;
    margin: 0 10px;
}

.calendar-arrow {
    cursor: pointer;
    height: 20px;
    width: 20px;
}
.calendar-table-cell-day-week.first-day {
    border-radius: 25px 0 0 0 ;
}
.calendar-table-cell-day-week.last-day {
    border-radius: 0 25px 0 0 ;
}
.calendar-table.ui.table:not(.unstackable) tr > th{
    border-bottom: 1px solid rgba(34,36,38,.1) !important;
    border-left: none !important;
}
/* Мобильные стили */
@media only screen and (max-width: 767px) {
    .calendar-table.ui.table:not(.unstackable) tbody,
    .calendar-table.ui.table:not(.unstackable) tr,
    .calendar-table.ui.table:not(.unstackable) tr > td,
    .calendar-table.ui.table:not(.unstackable) tr > th {
        width: 100% !important;
        display: revert !important;
        box-shadow: none !important;
    }

    /* Сбрасываем стили для ячеек */
    .calendar-table.ui.table:not(.unstackable) tr > td {
        border-right: 1px solid rgba(34,36,38,.1) !important;
        padding: 0.5em !important;
        font-weight: normal;
        font-size: 10px !important;
    }

    /* Сбрасываем стили для ячеек */
    .calendar-table.ui.table:not(.unstackable) tr > td,
    .calendar-table.ui.table:not(.unstackable) tr > th {
        padding: 0.5em !important;
    }
    .calendar-table.ui.table:not(.unstackable) .calendar-table-cell-day-week{
        padding: 5px 10px !important;
    }
    .calendar-table.ui.table:not(.unstackable) .calendar-table-cell-day{
        padding: 0 5px !important;
    }
    .calendar-month-label{
        font-size:10px;
        top:14px;
        left:35px;
        padding: 4px 25px;
    }
    .calendar-month-display {
        font-size: 10px;
        margin: 0 10px;
    }
    .calendar-arrow {
        cursor: pointer;
        height: 15px;
        width: 15px;
    }
}




