.profile-task:hover{
    background-color: #EBF6FF;
}
.profile-unplanned-tasks-container {
    width: 102%;
    box-shadow: 0 0 11px 2px rgba(34, 60, 80, 0.2);
    border-radius: 10px;
    min-height: 92px;
    margin-top: 5px;
    margin-left: -7px !important;
}
.employment-profile-task-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    padding-top: 1px;
}
.profile-task-number {
    max-width: 75px;
    min-width: 75px;
    border-radius: 10px 0 0 0;
    background-color: #F6FCFF;
}
.profile-task-company {
    margin-left: auto;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    padding-top: 1px;
}
@media only screen and (min-width: 300px){
    .employment-profile-task-text{
        max-width:100px;
    }
    .profile-task-company {
        max-width:120px;
    }
}
@media only screen and (min-width: 346px){
    .employment-profile-task-text{
        max-width:120px;
    }
    .profile-task-company {
        max-width:100px;
    }
}
@media only screen and (min-width: 360px){
    .employment-profile-task-text{
        max-width:120px;
    }
    .profile-task-company {
        max-width:120px;
    }
}
@media only screen and (min-width: 412px){
    .employment-profile-task-text{
        max-width:160px;
    }
    .profile-task-company {
        max-width:160px;
    }
}
@media only screen and (min-width: 424px){
    .employment-profile-task-text{
        max-width:180px;
    }
    .profile-task-company {
        max-width:180px;
    }
}
@media only screen and (min-width: 450px){
    .employment-profile-task-text{
        max-width:400px;
    }
    .profile-task-company {
        max-width:400px;
    }
}