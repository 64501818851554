.modal-pdf{
    position: static!important;
    width: auto;
    max-width: 790px!important;
    height: calc(100% - 140px)!important;
}
.pdf-content.scrolling.content{
    height: calc(100% - 70px)!important;
    max-height: 100%!important;
}

.sc-fHejqy.jRIYA-d{
    margin: 0!important;
}

#pdf-download {
    display: none;
}
.ui.dimmer {
    padding: 0;
}
    /*.modals.dimmer .ui.scrolling.modal {*/
    /*    margin: 0 auto;*/
    /*    width: 100%;*/
    /*    max-width: 991px;*/
    /*}*/

@media only screen and (max-width: 991px)
.modals.dimmer .ui.scrolling.modal {
    margin-top: 0;
    margin-bottom: 0;
}

@media only screen and (max-width: 768px)
.modals.dimmer .ui.scrolling.modal {
    margin-top: 0;
    margin-bottom: 0;
}

.ui.modal>.close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0, 0, 0, .87);
}




