.profile-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.profile-header-ctn {
    display: flex;
    position: relative;
    width: 95%;
    height: 50px;
}

.profile-title-h1 {
    color: #2185D0;
    font-weight: bolder;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    font-size: 17px;
    margin: 0;
    margin-bottom: 14px;
}

.profile-header {
    display: flex;
    width: 95%;
    gap: 21px;
}

.profile-icon {
    width: 111px;
    height: 111px;
    border-radius: 50%;
}

.ui.star.rating .active.icon {
    text-shadow: 0 0 0 transparent !important;
    color: red !important;
}

.ui.rating .selected.icon {
    color: red !important;
    opacity: 0.5 !important;
}

.ui.rating .hovered.icon {
    color: red !important;
}

.ui.rating .icon {
    background: 0 0 !important;
    color: #BFBFBF !important;
}

.profile-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
}

.profile-rating-text {
    font-size: 8px;
    color: #8C8C8C;
    text-align: center;
}

.profile-nav {
    width: 100%;
}

.profile-nav-container {
    width: 100%;
}

.profile-name {
    font-weight: 600;
    font-size: 15px;
    text-align: left;
    margin-bottom: 8px;
}

.profile-divider {
    height: 0.5px;
    background-color: #2185D0;
    margin-bottom: 11px;
}

.profile-nav-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & :hover {
        opacity: 0.7;
    }
}

.profile-nav-item-text {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 6px;
    cursor: pointer;
}

.profile-tasks {
    margin-top: 18px;
    width: 95%;
}

.profile-task {
    cursor: pointer;
}


.profile-title-h2 {
    margin: 0;
    color: #2185D0;
    font-weight: bold;
    font-size: 15px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.profile-tasks-container {
    width: 100%;
    box-shadow: 0 0 11px 2px rgba(34, 60, 80, 0.2);
    border-radius: 10px;
    min-height: 92px;
}

.profile-task {
    display: flex;
    align-items: center;
    width: 100%;
}

.profile-task-number {
    height: 100%;
    text-align: left;
    padding-top: 3px;
    padding-left: 5px;
    width: 71px;
}

.profile-task-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    padding-top: 1px;
    margin-left: 35px;
    width: 60%;
}

.profile-task-company {
    font-size: 13px;
    margin-right: 10px;
    margin-left: auto;
    text-align: left;
    width: 200px;

}

.profile-stats {
    width: 95%;
    margin-top: 24px;
}

.profile-stats-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.profile-stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-stats-number {
    width: 115px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: bold;
    border-radius: 10px;
    color: red;
}

.profile-stats-text {
    font-size: 13px;
    text-align: center;
    margin-top: 8px;
    max-width: 120px;
}

.profile-graph {
    width: 95%;
    margin-top: 37px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 450px) {


    .profile-task-number {
        width: 45px;
    }
}
