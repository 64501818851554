.user-login {
  position: static!important;
}
.user-login-toggle-show-password {
  cursor: pointer;
}

#eye-icon-id {
  cursor: pointer;
  margin-left: -10px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;
}

.login-form-submit-button {
  color: white;
  font-family: 'Muller-Bold', monospace;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #1E73BE;
  height: 45px;
  width: 100%;
  margin-right: -10%;
  border: 1px transparent;
  font-weight: 700;
}

.login-form-submit-button-disabled {
  color: white;
  font-family: 'Muller-Bold', monospace;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: darkgrey;
  height: 45px;
  width: 100%;
  margin-right: -10%;
  border: 1px transparent;
  font-weight: 700;
}

.container-buttons-auth {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container-button-confirm {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 85%;
  max-width: 85vw;
}

.registration-container {
  width: 100%;
}

.registration-input {
  height: 54px!important;
  width: 100%!important;
  border-radius: 15px!important;
  background: #EBE7E5!important;
  border: 1px gray!important;
  padding-left: 18px!important;
  outline: none!important;
  color: gray!important;
}

.button-confirm-registration {
  color: white!important;
  font-size: 14px!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  border-radius: 10px!important;
  background: #1E73BE !important;
  height: 45px!important;
  width: 100% !important;
  border: 1px transparent!important;
  font-weight: 700!important;
}

.container-button-registration {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 5px;
}

.registration-input-phone {
  display: flex!important;
  align-items: center!important;

  font-size: 13px !important;
  line-height: 16px;
  border-radius: 15px !important;
  padding: 0 !important;
  border: none !important;
  background: #EBE7E5!important;
  border-bottom: 1px solid #D9D9D9 !important;
  color: black!important;
  height: 54px!important;
}

.registration-input-phone input.search {
  border-radius: 15px !important;
  background: lightgrey!important;
}

.registration-input-phone:focus {
  border-bottom: 1px solid #2185D0 !important;
  background: #EBE7E5!important;
}

.registration-input-phone input[type="text"]:focus {
  border: none !important;
  border-bottom: 1px solid #2185D0 !important;
  background: #EBE7E5!important;
}


.registration-input-phone input[type="text"].search:focus {
  border: none !important;
  background: #EBE7E5!important;
}

#input-lastname {
  margin: 0 !important;
}

.registration-input-phone input#masked_phone,
.registration-input-phone .ui.selection.dropdown {
  border: none !important;
  padding-bottom: 4px;
  background: #EBE7E5!important;
  color: gray!important;
  font-size: 14px!important;
}

.registration-input-phone .ui.selection.dropdown {
  height: 13px !important;
  padding-left: 0;
  padding-right: 0;
  background: #EBE7E5!important;
}

.registration-input-phone input.search {
  padding: 0 !important;
  text-align: center;

}

.registration-input-phone div.divider.text,
.registration-input-phone input.search {
  width: 30px !important;
}

.registration-input-phone #dropdown_country {
  width: 60px !important;
}

.registration-input-phone .flag {
  padding-left: 10px!important;
  margin-bottom: 3px!important;
}

.registration-input-phone .icon {
  padding-left: 0 !important;
}

.container-line {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.line-or {
  flex: 1;
  height: 1px;
  background-color: lightgrey;
  margin: 0 10px;
}
.text-or {
  font-size: 19px;
  font-weight: bold;
}

.btn-mts-id {
  display: flex!important;
  justify-content: center !important;
  margin-top: 5px!important;
  padding: 8px!important;
}

.log-with-mts-id {
  text-transform: uppercase!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  gap: 6px!important;
  font-family: 'MTSWideBold', monospace!important;
  color: #FAFAFA!important;
  background: #1D2023!important;
  font-size: 14px!important;
  height: 52px!important;
  padding-right: 22px!important;
  padding-left: 22px!important;
  border-radius: 16pt!important;
  max-width: 512pt!important;
  width: 100%!important;
}

.forgot-pass {
  text-align: center!important;
  color: #0048FF!important;
  font-size: 12px;
  cursor: pointer;
}

.main-block-reset-pass {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 21px;
  margin-right: 15px;
  margin-left: 15px;
  padding-bottom: 15px;
}

.main-block-reset-pass-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-right: 15px;
  margin-left: 15px;
  padding-bottom: 15px;
}

.container-buttons-reset {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.enter-otp {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-container-reset-pass {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}


.header-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
  width: 89%;
  background: lightgrey;
}

.container-inp-one {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.container-inp-two {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.main-block3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.container-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
  max-width: 65vw;
}

.text-for-label {
  font-family: 'Muller';
  color: black;
  font-size: 14px;
  text-align: center;

}

#input-num {
  border-radius: 10px;
  width: 100%;
  height: 54px;
  font-family: 'Muller';
  background: #EBE7E5;
  color: #777777;
  font-size: 14px;
}

.input-numbers {
  border-radius: 10px;
  width: 100%;
  height: 54px;
  font-family: 'Muller';
  background: #EBE7E5;
  color: #777777;
  font-size: 14px;
}

.button-confirm-code {
  border-radius: 10px;
  background: #1E73BE;
  height: 45px;

  margin-left: -5%;

  display: flex;
  justify-content: center;
}

.text-for-link {
  color: white;
  font-family: 'Muller-Bold', monospace;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #1E73BE;
  height: 45px;
  width: 110%;
  margin-left: -5%;
  margin-right: -10%;
  border: 1px transparent;
  font-weight: 700;
}

.text-for-link-disabled {
  color: white;
  font-family: 'Muller-Bold', monospace;
  font-weight: 700;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 45px;
  width: 110%;
  margin-left: -5%;
  margin-right: -10%;
  border: 1px transparent;
  background: darkgrey;

}

.main-block3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.container-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.text-for-label {
  font-family: 'Muller';
  color: black;
  font-size: 14px;
  text-align: center;
}

#input-num {
  border-radius: 10px;
  width: 100%;
  height: 54px;
  font-family: 'Muller';
  background: #EBE7E5;
  color: #777777;
  font-size: 14px;
}

#input-numbers {
  border-radius: 10px;
  width: 100%;
  height: 54px;
  font-family: 'Muller';
  color: #777777;
  font-size: 14px;
}

.button1 {
  border-radius: 10px;
  background: #1E73BE;
  height: 45px;
  width: 110%;
  margin-top: 15px;
  margin-left: -5%;
  margin-right: -10%;
  display: flex;
  justify-content: center;
}

.text-for-link {
  color: white;
  font-family: 'Muller-Bold', monospace;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #1E73BE;
  height: 45px;
  width: 110%;
  margin-left: -5%;
  margin-right: -10%;
  border: 1px transparent;
  font-weight: 700;
}

.text-for-link-disabled {
  color: white;
  font-family: 'Muller-Bold', monospace;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 45px;
  width: 110%;
  margin-left: -5%;
  margin-right: -10%;
  border: 1px transparent;
  background: darkgrey;
}



.container-inputsss {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 61%;
  max-width: 65vw;
  margin-left: 2.5%;
}



.input-number12 {
  border-radius: 10px!important;
  width: 100% !important;
  height: 54px!important;
  background: #EBE7E5!important;
  color: #777777!important;
  font-size: 14px!important;
}

.container-text-registration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 37px;
}

.main-block-registration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.container-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 90%;
}

.container-inputss {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}

.container-inputs-registration {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 90%;
}

.text-for-label {

  color: black;
  font-size: 14px;
  text-align: center;
}

.input-number12 {
  border-radius: 10px!important;
  width: 100% !important;
  height: 54px!important;
  background: #EBE7E5!important;
  color: #777777!important;
  font-size: 14px!important;
}

.button2 {
  border-radius: 10px;
  background: #1E73BE;
  height: 45px;
  display: flex;
  justify-content: center;
}

.text-for-link-registration {
  color: white;
  font-family: 'Muller-Bold', monospace;
  font-size: 14px;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #1E73BE;
  height: 45px;
  width: 110%;
  font-weight: 700;
  border: 1px transparent;
}

.text-for-link-registration-disabled {
  color: white;
  font-family: 'Muller-Bold', monospace;
  font-size: 14px;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 700;
  height: 45px;
  width: 110%;
  border: 1px transparent;
  background: darkgrey;
}

.main-block5 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.text-for-label {

  color: black;
  font-size: 14px;
  text-align: center;
}

.container-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 25px;
}

.container-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.number-phone {
  font-weight: bold;
  color: #1E73BE;
  font-size: 16px;
  height: 16px;
}

#btnCode {
  height: 14px;
  background: none;
  border: 1px transparent;
}

.input-number {
  height: 54px;
  width: 60px;
  border-radius: 12px;
  text-align: center;
  color: #000000;

  font-size: 32px;
  border: 0px solid;
  background: #EBE7E5;
  font-weight: 700;
}

.text-again {
  font-size: 14px;
  color: #A7AFB7;
  text-align: center;
  cursor: pointer;
}

.container-again-button {
  display: flex;
  justify-content: center;
  align-items: center;

}

.edit-error {
  color: #D81524;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
}