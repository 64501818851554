.cart-product, .cart-summary {
 /* margin-top: 2.5em !important; */
  width: 90% !important;
}

.cart-product .content {
  padding-top: 2em !important;
}

.cart-product .content .column {
  padding: 0.5em !important;
}

.cart-buttons {
  position: absolute;
  top: -30px;
  right: 0;
}

.cart-buttons button,
.cart-button {
  border-radius: 50% !important;
}

.cart-quantity-label {
  padding-top: 0.7em !important;
}

.cart-quantity-input {
  max-width: 3em !important;
  margin-right: 0.3em !important;
}

.cart-quantity-input input {
  text-align: center !important;
  padding: 10px !important;
}

.goto-checkout {
  width: 90% !important;
  margin-left: 5% !important;
}
