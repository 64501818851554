.button-goto-payment-options,
.checkout-address-buttons-button {
  width: 90% !important;
  margin-left: 5% !important;
}

.checkout-steps-group {
  width: 90% !important;
  margin-left: 5% !important;
}

.checkout-steps-icon {
  font-size: 24px !important;
}

.checkout-steps-description {
  font-size: 12px !important;
}

.checkout-step {
  padding: 10px !important;
}