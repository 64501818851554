.checkboxes-box {
    display: flex;
    gap: 10px;
    margin: 20px 0 0 13px;
}


.review-card-content {
    background-color: #fff;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 0;
}

.review-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.reviews-list {
    margin: 0 auto;
    margin-top: 15px;
    width: 95%;
}

.review-card {
    margin-bottom: 21px;
}

.review-card-user-name {
    font-weight: bold;
    font-size: 13px;
    margin-left: 2px;

}

.review-card-header {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
}

.review-card-header-ctn {
    display: flex;
}

.review-card-title {
    background-color: #EBF6FF;
    border-radius: 5px;
    padding: 0 6px;
    font-size: 13px;
}

.review-card-date {
    color: #A4A4A4;
    font-size: 8px;
    margin-left: 7px;
}

.review-card-rating {
    margin-right: 5px !important;
}

.review-card-number {
    margin-bottom: 5px;
    margin-left: 2px;
    font-size: 15px;
    color: #2185D0;
    cursor: pointer;
}


.review-card-number:hover {
    opacity: 0.7;
}

.review-card-body {
    margin-left: 13px;
    margin-top: 7px;
}

.review-card-segment {
    display: flex;
    font-size: 13px;
    margin-bottom: 6px;
}

.review-card-field {
    font-weight: 600;
    margin-right: 17px;
    width: 61px;
}

.review-card-field-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 85%;
}

.review-row-container > span {
    display: inline-block; /* Гарантируем, что `<span>` корректно выравнивается */
    vertical-align: top; /* Устанавливаем вертикальное выравнивание */
}
.review-name {
    font-size: 15px;
    font-weight: 300;
    background-color: #EBF6FF;
    padding: 3px;
    border-radius: 5px;
    margin-left: 7px;
}

.review-date {
    padding-left: 10px;
    font-size: 10px;
    color: #A4A4A4;
    padding-top: 1px;
}

.review-rating {
    display: flex !important;
    margin: 0 10px 0 auto !important;
}

.ui.rating .active.icon {
    color: red!important;
}

.review-description {
    display: flex;
    flex-direction: column;
    padding: 5px 13px 0;
    gap: 5px;
}

.review-description b {
    font-size: 15px;
}

.review-client-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 9px;
    gap: 5px;
}

.review-client-rating span {
    padding-top: 1px;
    line-height: 1;
    color: #A4A4A4;
}

.review-row-container {
    display: flex;
    align-items: flex-start; /* Выравниваем элементы по верху */
    gap: 10px; /* Задаём промежуток между элементами */
}

.review-row-container span:has(b) {
    width: 71px;
}

.review-text {
    max-width: 85%;
    white-space: normal; /* Разрешаем перенос строк */
    word-wrap: break-word; /* Переносим длинные слова */
}


@media (max-width: 400px) {
    .review-card-title {
        font-size: 11px;
    }

    .review-card-rating {
        font-size: 9px;
    }

    .review-card-segment {
        font-size: 11px;
    }

    .review-card-user-name {
        font-size: 11px;
    }
    .review-client-rating {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 9px;
        gap: 5px;
    }

    .review-client-rating span {
        padding-top: 1px;
        line-height: 1;
        color: #A4A4A4;
    }

    .review-row-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
    }

    .review-row-container span:has(b) {
        width: 71px;
    }

    .review-text {
        max-width: 70%!important;
        font-size: 12px;
        line-height: 16px;
    }
}

.ui.rating .icon.selected {
    color: red!important;
}
@media (max-width: 717px) {
    .review-description b {
        font-size: 12px;
    }
    .review-row-container span:first-child {
        width: 60px;
    }
    .review-row-container {
        gap: 5px; /* Уменьшаем промежутки для экономии места */
    }

    .review-text {
        font-size: 12px; /* Уменьшаем размер текста */
        max-width: 80%; /* Ограничиваем ширину текста */
    }

    .review-card-header {
         font-size: 15px;
     }
    .checkbox-box{
        font-size:10px !important;
    }
    .review-date {
        font-size: 8px;
    }
    .review-name {
         font-size: 13px;
     }
    .reviews-dropdown {
        font-size: 13px;
    }
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
    border: 1px solid #2185D0;
}

.ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after  {
    background-color: #2185D0;
}

.ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {
    background-color: #2185D0;
}

.ui.checkbox input:checked:focus~.box:after, .ui.checkbox input:checked:focus~label:after, .ui.checkbox input:not([type=radio]):indeterminate:focus~.box:after, .ui.checkbox input:not([type=radio]):indeterminate:focus~label:after {
    color: #2185D0;
}

.ui.radio.checkbox input:focus:checked~.box:after, .ui.radio.checkbox input:focus:checked~label:after {
    background-color: #2185D0;
}