.task-document-icons {
  display: flex;
  flex-direction: column;
}
.task-document-icon {
  font-size: 1.5em!important;
}
/* fullscreen preview */
.image-preview {
  position: relative;
  text-align: center;
}
.image-preview-fullscreen > img {
  width: 100%;
  height: auto;
}
.ui.modal.make-photo {
  top: 60px!important;
}
.task-file-state-text-deleted {
  color: red;
}
.task-card-action-buttons {
  padding: 0!important;
}
.delete-uploaded-image {
  float: right;
  margin-bottom: 1em!important;
}
.sigCanvas {
  border: 1px solid black;
  width: 100%;
  height: 300px;
}
.task-card-header {
  padding: 0.5em!important;
  position: relative;
}
.task-card-header .label {
  bottom: -2.3em!important;
}
.task-steps-header,
.task-conclusion-header {
  padding: 0.5em 0.5em 0 0.5em!important;
}
.task-steps-icon {
  padding-left: 0.5em!important;
}
.task-step-mandatory {
  float: right;
  padding-right: 1em;
  color: red!important;
}
.task-step-manual-message,
.task-step-document-placeholder{
  margin: 1em!important;
  width: calc(100% - 2em)!important;
}
.task-step-document-placeholder .ui.list .item .task-file-buttons {
  float: right!important;
  border-bottom: solid 1px lightgray;
  padding: 0 0 1em 0;
  margin-bottom: 1em;
}
.task-step-document-placeholder .ui.list .item:last-child .task-file-buttons {
  border-bottom: none;
  padding: 0;
  margin-bottom: revert;
}
.task-file-buttons button {
  border-radius: 50%!important;
}
.task-document-modal-divider {
  margin: 0 0 1em 0!important;
}
.task-geolocation-message {
  margin-top: 0!important;
  border-radius: 0!important;
}
.ui.card, .ui.cards>.card {
  width: 90%!important;
}
.task-step-title {
  padding: 1em!important;
  width: calc(100% - 38px)!important;
}
.task-conclusion textarea {
  min-height: 15em;
  font-weight: 100;
}
.task-conclusion .task-recommended-list,
.task-conclusion-text,
.task-conclusion-save{
  width: 95%!important;
    margin: 0 auto 0.5em!important;
}
.task-conclusion .task-recommended-list .label {
  position: relative;
  background-color: #2185d0;
  color: white;
  width: 100%;
}
.task-conclusion .task-recommended-list .label .icon.delete {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}
.task-step-document-placeholder {
  margin: 1em 0!important;
  width: 100%!important;
}
.task-update-loader {
  position: fixed!important;
  top: 50%!important;
  left: 50%!important;
}
.task-user-manual-link.NONE {
  pointer-events: none;
  cursor: default;
  color: gray;
}
.task-step-header {
  display: flex;
}
.task-user-manual-link {
  margin: auto 1em;
  float: left;
}
.task-done-toggle {
  margin: auto;
  float: right;
}
.task-card-amount {
  width: 100%;
  text-align: end;
  font-weight: bold;
}
.task-card-step-amount {
  padding: 0!important;
  margin: auto 0!important;
  text-align: end;
}
.task-card-step-row-buttons {
  margin-top: 0!important;
  padding-top: 0!important;
}
.task-card-step-quantity .basic.label {
  font-weight: 100!important;
  background: transparent!important;
}
.task-card-step-quantity .label {
  font-weight: 100!important;
}
.task-card-step-quantity .input-field {
  width: 4em!important;
}
.task-card-field-error {
  font-size: 10px;
  color: red;
  text-align: end;
  width: 100%;
  display: block!important;
}