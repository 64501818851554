.task-ribbon-state,
.task-ribbon-not-paid {
    position: absolute!important;
    top: 0.5em!important;
    height: fit-content;
    width: 50%!important;
    padding: 0.5em!important;
}
.rating_client{
    float: right;
}
.task-card-header-order-number {
    width: 50%;
    padding: 0.5em;
    float: left;
}
.task-card-header-divider {
    margin: 1em 0 !important;
}
.task-card-customer-data {
    padding-bottom: 1em;
}
.task-card-col-image {
    width: 30%;
}
.task-card-col-product {
    width: 70%;
}
.task-card-customer-name {
    font-weight: 600;
}
.task-card-header {
    margin-bottom: 0!important;
}
.task-card-button-cancel-restore {
    position: absolute;
    right: 0.2em;
    bottom: 0.5em;
    background: transparent!important;
    font-weight: 400!important;
}
.task-card-button-cancel-restore.canceled {
    color: #2185d0!important;
    font-weight: 700!important;
}

.task-document-icons a i {
    margin-top: 0.2em!important;
}
.task-card-open-steps {
    float: right;
    margin-right: 0!important;
}
.task-card-confirmed-date {
    text-align: center;
}
.field-info{
    margin: 0 0 1em 0;
}
