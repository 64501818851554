.modal-prompt {
    position: static!important;
}
.modal-prompt-field {
    font-size: 13px !important;
    width: 100%!important;
}

.modal-prompt-field-error {
    font-size: 10px;
    color: red;
    width: 100%;
}